import React from "react";

// Possibly update the server to send winner or not
export default function GameOver({ currentPlayer, playerRound }) {
  const winner = [...playerRound.players].sort((a, b) => b.score - a.score)[0];

  return (
    <div>
      {winner.id === currentPlayer.id ? (
        <h1>You Win!</h1>
      ) : (
        <h1>Game Over Loser</h1>
      )}
    </div>
  );
}
