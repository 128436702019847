import React from "react";

import ScreenPicker from "../../components/ScreenPicker";

import { GET_GAME, PLAYER_ROUND_UPDATED } from "./queries";

// Component Screens
import PlayerWaiting from "./components/screens/PlayerWaiting";
import PlayerSelectReady from "./components/screens/PlayerSelectReady";
import PlayerSelectCategory from "./components/screens/PlayerSelectCategory";
import PlayerSubmitAnswer from "./components/screens/PlayerSubmitAnswer";
import PlayerSelectAnswer from "./components/screens/PlayerSelectAnswer";
import GameOver from "./components/screens/GameOver";

const components = {
  PlayerSelectCategory,
  PlayerWaiting,
  PlayerSubmitAnswer,
  PlayerSelectAnswer,
  PlayerSelectReady,
  GameOver,
  // Like answers
};

// TODO: Should screen logic displays etc.
// be moved to its own component and then just pass in
// options like screens?
export default function Player({
  match: {
    params: { code, player_id },
  },
}) {
  return (
    <ScreenPicker
      code={code}
      player_id={player_id} // TODO: Swap to currentPlayer if thats fetched
      components={components}
      query={GET_GAME}
      subscription={PLAYER_ROUND_UPDATED}
    />
  );
}

// Mutation for give answer can respond with success or error type for its corrects
