import { gql } from "@apollo/client";

const PLAYER_ROUND_FRAGMENT = gql`
  fragment PlayerRoundDetails on PlayerRound {
    __typename
    ... on PlayerSelectReady {
      _
    }
    ... on PlayerSelectCategory {
      categories {
        id
        name
      }
    }
    ... on PlayerWaiting {
      reason
    }
    ... on PlayerSubmitAnswer {
      question {
        text
        suggestions
      }
    }
    ... on PlayerSelectAnswer {
      answers {
        id
        answer
      }
    }
    ... on GameOver {
      players {
        id
      }
    }
  }
`;

export const PLAYER_ROUND_UPDATED = gql`
  subscription onPlayerRoundUpdated($code: String!, $player_id: Int!) {
    PlayerRoundUpdated(code: $code, player_id: $player_id) {
      ...PlayerRoundDetails
    }
  }
  ${PLAYER_ROUND_FRAGMENT}
`;

export const GET_GAME = gql`
  query Game($code: String!, $player_id: Int!) {
    Game(code: $code) {
      id
      code
      playerRound(player_id: $player_id) {
        ...PlayerRoundDetails
      }
      currentPlayer(player_id: $player_id) {
        id
        name
        ready
        score
      }
    }
  }
  ${PLAYER_ROUND_FRAGMENT}
`;

/**
 * MUTATIONS
 */
export const SELECT_CATEGORY = gql`
  mutation SelectCategory(
    $code: String!
    $player_id: Int!
    $category_id: Int!
  ) {
    SelectCategory(
      code: $code
      player_id: $player_id
      category_id: $category_id
    )
  }
`;

export const SUBMIT_ANSWER = gql`
  mutation SubmitAnswer($code: String!, $player_id: Int!, $answer: String!) {
    SubmitAnswer(code: $code, player_id: $player_id, answer: $answer)
  }
`;

export const SELECT_ANSWER = gql`
  mutation SelectAnswer($code: String!, $player_id: Int!, $answer_id: Int!) {
    SelectAnswer(code: $code, player_id: $player_id, answer_id: $answer_id)
  }
`;
