import React from "react";

const SPEED = 0.1

export default class Progressbar extends React.Component{
  constructor(){
    super();
    this.state = {
      progress: 100,
      speed: SPEED,
      color: "#ff0050"
    }
    this.frame = this.frame.bind(this);
    this.green = this.green.bind(this);
    this.red = this.red.bind(this);
  }
    componentDidMount() {
      this.interval = setInterval(() => this.frame(), 100);
    }
    
    frame() {
      if (this.state.progress <= 100 && this.state.progress > 0){
        this.setState((prevState, props) => {
          const progrress = prevState.progress - this.state.speed
          this.props.onProgress(progrress)
          return {
            progress: progrress,
            color: "#" + this.red() + this.green() + "50"
          }
        });
      }

      if (this.state.progress === 0) {
        this.props.onDone();
        clearInterval(this.interval)
      }
    }

    componentWillUnmount() {
      clearInterval(this.interval);
    }
    green() {
      let progress = this.state.progress;
      progress *= 2.55;
      progress = Math.round(progress);
      progress = progress.toString(16);
      return progress;
    }
    red() {
      let progress = this.state.progress;
      progress *= 2.55;
      progress = Math.round(progress);
      progress = 255 - progress;
      progress = progress.toString(16);
      return progress;
    }
  render(){
    return (
      <div id="myBar" style={{
          width: this.state.progress + "%",
          backgroundColor: this.state.color
        }}>
         <div id="label">Loaded {Math.floor(this.state.progress)}%</div> 
      </div>);
  }  
}