import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { ROUND_COUNTDOWN } from "pages/Host/queries";
import ProgressTimer from "components/progressTimer";

export default function Question({ code, hostRound }) {
  // This should run at end of countdown
  // const [updateRound] = useMutation(UPDATE_ROUND);
  const [updateProgress] = useMutation(ROUND_COUNTDOWN);
  let [countdown] = useState(true);

  function onDone() {
    // updateRound({ variables: { code } });
  }

  function onProgress(seconds) {
    updateProgress({ variables: { code, seconds: parseInt(seconds) } });
  }

  return (
    <div>
      {countdown && <ProgressTimer onProgress={onProgress} onDone={onDone} />}
      <div>
        <h1>Waiting on Answers</h1>
        <ul>
          {hostRound.waiting.map((player, i) => (
            <li key={player.id}>
              {player.id} - {player.answered ? "Answered" : "Waiting"}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
