import React, { useEffect } from "react";
import UIfx from "uifx";
import ReactAudioPlayer from "react-audio-player";

import popAudio from "assets/audio/Pop.mp3";
import sosumiAudio from "assets/audio/Sosumi.mp3";
import fartAudio from "assets/audio/fart-01.mp3";
import jazz from "assets/audio/bensound-jazzcomedy.mp3";

import Init from "./Init";
import PlayerList from "../components/PlayerList";
import Logo from "../../../components/Logo";

import { PLAYER_JOINED, PLAYER_READY, GAME_STARTED } from "../queries";

export default function Lobby({
  code,
  ready,
  qr_code,
  subscribeToMore,
  hostRound,
  // match,
  // history
}) {
  const popFx = new UIfx(popAudio);
  const fartFx = new UIfx(fartAudio);
  const sosumiFx = new UIfx(sosumiAudio);

  /**
   * Subscribes to player joining event
   */
  useEffect(() => {
    const unsubscribePlayerAdd = subscribeToMore({
      document: PLAYER_JOINED,
      variables: { code },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const player = subscriptionData.data.PlayerJoined;
        const hostRound = Object.assign({}, prev.Game.hostRound, {
          players: [player, ...prev.Game.hostRound.players],
        });

        // Play joined sound
        popFx.play();

        return {
          Game: {
            ...prev.Game,
            hostRound,
          },
        };
      },
    });

    return () => {
      // Unsubscribe to prevent multiple connections each render
      unsubscribePlayerAdd();
    };
  });

  /**
   * Subscribe to player readying to play
   */
  useEffect(() => {
    const unsubscribePlayerReady = subscribeToMore({
      document: PLAYER_READY,
      variables: { code },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const player = subscriptionData.data.PlayerReady;
        const playerIndex = prev.Game.hostRound.players.findIndex(
          (x) => x.id === player.id
        );
        const playersArray = [...prev.Game.hostRound.players];

        playersArray[playerIndex] = player;

        const hostRound = Object.assign({}, prev.Game.hostRound, {
          players: playersArray,
        });

        // play ready sound
        if (player.ready) {
          fartFx.play();
        } else {
          sosumiFx.play();
        }

        return {
          Game: {
            ...prev.Game,
            hostRound,
          },
        };
      },
    });

    return () => {
      unsubscribePlayerReady();
    };
  });

  /**
   * Subscribes to game ready event
   * This is all players ready
   */
  useEffect(() => {
    const unsubscribeGameStarted = subscribeToMore({
      document: GAME_STARTED,
      variables: { code },
      updateQuery: (prev) => {
        return {
          Game: {
            ...prev.Game,
            ready: true,
          },
        };
      },
    });

    return () => {
      unsubscribeGameStarted();
    };
  });

  if (ready) {
    return <Init code={code} />;
  }

  return (
    <section
      className="min-h-screen flex items-stretch"
      style={{ background: "#608267" }}
    >
      <div className="lg:flex w-3/5 flex-col place-items-center">
        <Logo />
        <img
          src={qr_code}
          alt={code}
          style={{ width: "25%", margin: "10px" }}
        />
        <div style={{ textAlign: "center" }}>
          <h4 style={{ marginBottom: "5px" }}>Scan to play!</h4>
          <h3>Your Room Code Is</h3>
        </div>
        <div>
          <a
            rel="noreferrer"
            href={`http://${window.location.host}/join-game/${code}`}
            target="_blank"
            className="rounded-full py-3 px-6 bg-gray-900 text-white inline-block m-4"
          >
            {code}
          </a>
        </div>
      </div>
      <div className="lg:w-2/5 w-full flex md:px-16 px-0 z-0">
        <div className="min-h-screen flex-1 p-4 flex ">
          <div className="bg-white w-full md:max-w-4xl rounded-lg shadow">
            <div className="px-6">
              <PlayerList players={hostRound.players} />
            </div>
          </div>
        </div>
      </div>
      <ReactAudioPlayer
        src={jazz}
        autoPlay
        loop
        volume={0.3}
        controls
        style={{ position: "absolute", bottom: "0" }}
      />
    </section>
  );
}
