import React from "react";
import { useMutation } from "@apollo/client";

import { SELECT_CATEGORY } from "../../../queries";

export default function PlayerSelectCategory({ player_id, code, playerRound }) {
  const [selectCategory] = useMutation(SELECT_CATEGORY);

  return (
    <div
      style={{
        // display: "flex",
        height: "100vh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#697c69",
      }}
      className="container"
    >
      {playerRound.categories.length === 0 && <p>Waiting...</p>}
      <div className="row">
        <div className="col">
          <h1
            style={{ color: "#fff", textAlign: "center" }}
            className="mt-4 mb-4"
          >
            Select Category
          </h1>
        </div>
      </div>
      <ul style={{ width: "100%", padding: 0 }}>
        {playerRound.categories.map((category) => (
          <li
            onClick={() =>
              selectCategory({
                variables: {
                  code,
                  player_id: parseInt(player_id),
                  category_id: parseInt(category.id),
                },
              })
            }
            key={category.id}
            className="btn btn-lg btn-dark d-block mb-5"
            style={{
              fontSize: "2em",
            }}
          >
            {category.name}
          </li>
        ))}
      </ul>
    </div>
  );
}
