import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

// Hooks
import useLocalStorage from "../../../hooks/useLocalStorage";

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    Login(email: $email, password: $password)
  }
`;

export default function Login({ toggleShowLogin }) {
  const history = useHistory();
  const [, setToken] = useLocalStorage("access_token");
  const [Login] = useMutation(LOGIN, {
    onCompleted: ({ Login }) => {
      setToken(Login);
      history.push(`/profile`);
    },
  });

  const [login, setLogin] = useState({
    email: "",
    password: "",
  });

  return (
    <div className="flex justify-center min-h-screen">
      <div className="container sm:mt-40 mt-24 my-auto max-w-md border-2 border-gray-200 p-3 bg-white">
        <div className="text-center my-6">
          <h1 className="text-3xl font-semibold text-gray-700">Bullshïttè</h1>
          <p className="text-gray-500">It's French For Fun!</p>
        </div>
        <div className="m-6">
          <form className="mb-4">
            <div className="mb-6">
              <label
                htmlFor="email"
                className="block mb-2 text-left text-sm text-gray-600 dark:text-gray-400"
              >
                Email Address
              </label>
              <input
                type="email"
                name="email"
                id="email"
                onChange={(event) =>
                  setLogin({ ...login, email: event.target.value })
                }
                placeholder="Your email address"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 text-black dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
              />
            </div>
            <div className="mb-6">
              <div className="flex justify-between mb-2">
                <label
                  htmlFor="password"
                  className="text-sm text-gray-600 dark:text-gray-400"
                >
                  Password
                </label>
                {/* <a
                  href="#!"
                  className="text-sm text-gray-400 focus:outline-none focus:text-indigo-500 hover:text-indigo-500 dark:hover:text-indigo-300"
                >
                  Forgot password?
                </a> */}
              </div>
              <input
                type="password"
                name="password"
                id="password"
                onChange={(event) =>
                  setLogin({ ...login, password: event.target.value })
                }
                placeholder="Your password"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 text-black dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
              />
            </div>
            <div className="mb-6">
              <button
                type="button"
                onClick={() => Login({ variables: { ...login } })}
                className="w-full px-3 py-4 text-white bg-indigo-500 rounded-md hover:bg-indigo-600 focus:outline-none duration-100 ease-in-out"
              >
                Sign in
              </button>
            </div>
            <p className="text-sm text-center text-gray-400">
              Don&#x27;t have an account yet?
              <span
                onClick={() => toggleShowLogin(false)}
                className="font-semibold text-indigo-500 focus:text-indigo-600 focus:outline-none focus:underline"
              >
                &nbsp;Sign up
              </span>
              .
            </p>
          </form>
          <div className="flex flex-row justify-center mb-8">
            <span className="absolute bg-white px-4 text-gray-500">
              or sign-in as guest
            </span>
            <div className="w-full bg-gray-200 mt-3 h-px"></div>
          </div>
          <div className="flex flex-row gap-2">
            <button
              onClick={() => {
                history.push(`/join-game`);
              }}
              className="bg-green-500 text-white w-full p-2 flex flex-row justify-center gap-2 items-center rounded-sm hover:bg-green-600 duration-100 ease-in-out"
            >
              Guest Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
