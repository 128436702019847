import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";

import { UPDATE_ROUND } from "../queries";

export default function RoundIntro({ code, hostRound }) {
  const [updateRound] = useMutation(UPDATE_ROUND);

  useEffect(() => {
    let timer = setTimeout(() => updateRound({ variables: { code } }), 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [updateRound, code]);

  return (
    <div
      className="h-screen items-center justify-center flex flex-col"
      style={{ background: "#608267" }}
    >
      <div className="items-center justify-center w-full p-8 flex flex-col">
        <div className="bg-gray-400 items-center justify-center md:w-1/2 w-full shadow-md p-5 h-auto rounded-lg blur-3 bg-opacity-50 flex flex-col col-span-12">
          <h4 className="text-orange-300 text-2xl">Round {hostRound.number}</h4>
          <h2 className="text-gray-300   text-5xl text-center mt-2 mb-20">
            Correct answers are worth {hostRound.points} points this round.
          </h2>
        </div>
      </div>
      {/* <button onClick={() => playAudio()}>Game Stuck?</button> */}
    </div>
  );
}
