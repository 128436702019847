import React, { useEffect } from "react";
// import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";

export default function ScreenPicker({
  code,
  player_id,
  components,
  query,
  subscription,
  location,
  match,
}) {
  const variables = { code };
  /**
   * TODO:
   * - Make it a player subscription for their individual state
   * - This will allow each player to have different states
   * - player_id is currently using parseInt from url this is dangerous
   */
  if (player_id) {
    variables.player_id = parseInt(player_id);
  }
  // const history = useHistory();
  const { loading, data, subscribeToMore } = useQuery(query, {
    variables,
  });
  const pageType = player_id ? "playerRound" : "hostRound";

  useEffect(() => {
    const unsubscribeRoundUpdated = subscribeToMore({
      document: subscription,
      variables,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        /**
         * Determine the pageType
         * Convert from HostRoundUpdated or PlayerRoundUpdated
         * Make the data passed down playerRound or hostRound for consistency in componenet
         */
        return {
          Game: {
            ...prev.Game,
            [pageType]:
              subscriptionData.data[
                `${pageType.charAt(0).toUpperCase() + pageType.slice(1)}Updated`
              ],
          },
        };
      },
    });

    return () => {
      // Unsubscribe to prevent multiple connections each render
      unsubscribeRoundUpdated();
    };
  });

  // TODO: FOUC is happening here due to onHostRoundUpdated updating
  // Loading Game
  if (loading && !data?.Game) return <div>Loading...</div>;

  // No Game found or is archived
  // if (!data.Game) history.push("/"); // Has error

  // Get current round type
  // TODO: default and error case if undefined
  const CurrentScreen = components[data.Game[pageType].__typename];

  return (
    <CurrentScreen
      {...data.Game}
      location={location}
      match={match}
      player_id={player_id}
      subscribeToMore={subscribeToMore}
    />
  );
}
