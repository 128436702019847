import React, { useState } from "react";
import { useMutation, useSubscription } from "@apollo/client";
import { SUBMIT_ANSWER } from "../../../queries";
import { gql } from "@apollo/client";

const ROUND_COUNTDOWN = gql`
  subscription RoundCountdown($code: String!) {
    RoundCountdown(code: $code)
  }
`;

export default function PlayerSubmitAnswer({ player_id, code, playerRound }) {
  const [submitAnswer] = useMutation(SUBMIT_ANSWER);

  let [answer, setAnswer] = useState("");
  const {
    question: { text, suggestions },
  } = playerRound;

  return (
    <div
      style={{
        // display: "flex",
        height: "100vh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#697c69",
      }}
      className="container"
    >
      <CountDown code={code} />
      <div className="row">
        <div className="col">
          <h1
            style={{ color: "#fff", textAlign: "center" }}
            className="mt-3"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
      </div>
      <input
        type="text"
        name="answer"
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
        placeholder="Enter Answer"
        autoComplete="off"
        className="form-control form-control-lg mb-4 mt-4"
      />
      <button
        onClick={() =>
          // Need validation for empty etc.
          submitAnswer({
            variables: {
              code,
              player_id: parseInt(player_id),
              answer,
            },
          })
        }
        className="btn btn-lg btn-dark d-block mb-5"
        style={{
          fontSize: "2em",
          width: "100%",
        }}
      >
        Submit
      </button>
      <button
        onClick={() =>
          submitAnswer({
            variables: {
              code,
              player_id: parseInt(player_id),
              answer:
                suggestions[Math.floor(Math.random() * suggestions.length)],
            },
          })
        }
        className="btn btn-lg btn-dark d-block mb-5"
        style={{
          fontSize: "2em",
          width: "100%",
        }}
      >
        Lie For Me
      </button>
    </div>
  );
}

function CountDown({ code }) {
  const { data } = useSubscription(ROUND_COUNTDOWN, { variables: { code } });
  return <div>{data?.RoundCountdown}</div>;
}
