import React from "react";
import { useMutation } from "@apollo/client";

import { SELECT_ANSWER } from "../../../queries";

export default function PlayerSelectCategory({ player_id, code, playerRound }) {
  const [selectAnswer] = useMutation(SELECT_ANSWER);

  // TODO: what happens after selecting? Should it move to waiting if true?
  // Should we push to say show waiting?
  // Same goes for PlayerSubmitAnswer
  return (
    <div
      style={{
        // display: "flex",
        height: "100vh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#697c69",
      }}
      className="container"
    >
      <div className="row">
        <div className="col">
          <h1
            style={{ color: "#fff", textAlign: "center" }}
            className="mt-4 mb-4"
          >
            Select Answer
          </h1>
        </div>
      </div>
      <ul style={{ width: "100%", padding: 0 }}>
        {playerRound.answers.map((answer) => (
          <li
            onClick={() =>
              selectAnswer({
                variables: {
                  code,
                  player_id: parseInt(player_id),
                  answer_id: parseInt(answer.id),
                },
              })
            }
            key={answer.id}
            className="btn btn-lg btn-dark d-block mb-5"
            style={{
              fontSize: "2em",
            }}
          >
            {answer.answer}
          </li>
        ))}
      </ul>
    </div>
  );
}
