import React from "react";
import { useSpring, animated } from "react-spring";
import { useMutation } from "@apollo/client";

import { UPDATE_ROUND } from "../queries";

export default function ShowAnswers({ code, hostRound }) {
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });
  const [updateRound] = useMutation(UPDATE_ROUND);

  return (
    <div>
      <h1>Show Answers</h1>
      <animated.div style={props}>
        <ul>
          {hostRound.answers.map((answer) => (
            <li key={answer.id}>
              {answer.answer}
              <ul>
                {answer.players.map((player) => (
                  <li key={player.id}>{player.name}</li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </animated.div>
      <button onClick={() => updateRound({ variables: { code } })}>
        Game Stuck?
      </button>
    </div>
  );
}
