import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

import Logo from "../../components/Logo";

const JOIN_GAME = gql`
  mutation Joingame($code: String!, $name: String!) {
    JoinGame(code: $code, name: $name) {
      id
    }
  }
`;

export default function JoinGame({
  match: {
    params: { code },
  },
}) {
  const history = useHistory();
  const [login, setLogin] = useState({
    code: code || "",
    name: "",
  });

  const [joinGame] = useMutation(JOIN_GAME, {
    onCompleted: ({ JoinGame }) => {
      if (JoinGame) {
        history.push(`/player/${JoinGame.id}/game/${login.code}`);
      } else {
        alert("No Room Matching. Make Sure You Put The Right Room Code");
      }
    },
  });

  return (
    <div
      className="flex justify-center min-h-screen"
      style={{ background: "#608267" }}
    >
      <div className="container sm:mt-40 mt-24 my-auto max-w-md p-3">
        <div className="text-center my-6">
          <Logo />
        </div>
        <div className="m-6">
          {/* Make re-usable inputs */}
          <input
            autoCapitalize="off"
            autoCorrect="off"
            autoComplete="off"
            type="text"
            name="name"
            onChange={(event) =>
              setLogin({ ...login, name: event.target.value.toUpperCase() })
            }
            value={login.name}
            placeholder="Player Name"
            className="w-full px-3 py-2 placeholder-gray-800 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-gray-800 focus:border-gray-300 dark:bg-gray-700 text-black dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500 mb-6"
          />
          <input
            maxLength="4"
            autoCapitalize="off"
            autoCorrect="off"
            autoComplete="off"
            type="text"
            name="code"
            onChange={(event) =>
              setLogin({ ...login, code: event.target.value.toUpperCase() })
            }
            value={login.code}
            placeholder="Room Code"
            className="w-full px-3 py-2 placeholder-gray-800 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-gray-800 focus:border-gray-300 dark:bg-gray-700 text-black dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500 mb-6"
          />
          <button
            disabled={!login.code || !login.name}
            onClick={() => joinGame({ variables: { ...login } })}
            className="w-full px-3 py-4 text-white bg-black rounded-md hover:bg-gray-800 focus:outline-none duration-100 ease-in-out"
          >
            Join
          </button>
        </div>
      </div>
    </div>
  );
}
