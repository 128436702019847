import React from "react";

export default function Logo() {
  return (
    <>
      {/* <img
                src={Logo}
                alt="Bullshitte: Its French for Fun!"
                style={{ width: "100%" }}
              /> */}
      <h1
        style={{
          fontSize: "5em",
          fontFamily: "'Marck Script', cursive",
          color: "#fff",
          // background: "linear-gradient(#814e2d, #333)",
          // webkitBackgroundClip: "text",
          // webkitTextFillColor: "transparent",
        }}
      >
        Bullsh&iuml;tt&egrave;
      </h1>
      <h2
        style={{
          fontSize: "2em",
          fontFamily: "'Lobster', cursive",
        }}
      >
        Its French for Fun!
      </h2>
    </>
  );
}
