import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";

// Components
import Login from "./components/login";
import Signup from "./components/signup";
import useLocalStorage from "hooks/useLocalStorage";

import Background from "../../assets/fresh-bullshit-served-daily-prints.jpeg";

const ME = gql`
  query Session($token: String!) {
    Session(token: $token) {
      success
    }
  }
`;

export default function Dashboard() {
  const history = useHistory();
  const [storedValue] = useLocalStorage("access_token");
  const { loading, data } = useQuery(ME, { variables: { token: storedValue } });
  const [showLogin, toggleShowLogin] = useState(true);

  const sessionSuccess = data?.Session?.success;

  useEffect(() => {
    if (sessionSuccess) {
      history.push(`/profile`);
    }
  }, [sessionSuccess, history]);

  return loading ? (
    <div>Loading</div>
  ) : (
    <section className="min-h-screen flex items-stretch text-white">
      <div
        className="lg:flex w-1/2 hidden bg-gray-500 bg-no-repeat bg-cover relative items-center"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundSize: "contain",
        }}
      >
        {/* <div className="w-full px-24 z-10">
          <h1 className="text-5xl font-bold text-left tracking-wide">
            Bullshitte
          </h1>
          <p className="text-3xl my-4">It's French For Fun</p>
        </div> */}
      </div>
      <div className="lg:w-1/2 w-full flex items-center justify-center text-center md:px-16 px-0 z-0 bg-gray-100">
        <div className="w-full py-6 z-20">
          {showLogin ? (
            <Login toggleShowLogin={toggleShowLogin} />
          ) : (
            <Signup toggleShowLogin={toggleShowLogin} />
          )}
        </div>
      </div>
    </section>
  );
}
