import React from "react";

import { GET_GAME, HOST_ROUND_UPDATED } from "./queries";

import ScreenPicker from "../../components/ScreenPicker";

// Component Screens
import Lobby from "./screens/Lobby";
import Question from "./screens/Question";
import RoundIntro from "./screens/RoundIntro";
import SelectCategory from "./screens/SelectCategory";
import ShowAnswers from "./screens/ShowAnswers";
import ShowPlayers from "./screens/ShowPlayers";
import ShowScoring from "./screens/ShowScoring";
import WaitingAnswers from "./screens/WaitingAnswers";
import WaitingSelectAnswers from "./screens/WaitingSelectAnswers";
import GameOver from "./screens/GameOver";

/**
Lobby
Round Info
Show Players
Choose Category
Selected Category
Read Question
Question timer (waiting on players)
Pick answer timer
Show Player Selections
 - correct
 - lie
 - truth
Show scores
 */

const components = {
  Lobby,
  Question,
  RoundIntro,
  SelectCategory,
  ShowAnswers,
  ShowPlayers,
  ShowScoring,
  WaitingAnswers,
  WaitingSelectAnswers,
  GameOver,
};

/**
 * Game Manager
 * - This controls fetching data and show current round
 * - TODO: move subscriptions to their components?
 */
export default function Game({ match, location, history }) {
  return (
    <ScreenPicker
      code={match.params?.code}
      components={components}
      query={GET_GAME}
      subscription={HOST_ROUND_UPDATED}
      location={location}
      history={history}
      match={match}
    />
  );
}
