import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import UIfx from "uifx";
import { useSpring, animated } from "react-spring";

import pingAudio from "assets/audio/Ping.mp3";
import useInterval from "hooks/useInterval";
import { UPDATE_ROUND } from "../queries";

function Countdown({ count }) {
  const pingFx = new UIfx(pingAudio);
  const styles = useSpring({
    loop: true,
    to: [
      { opacity: 1, color: "#fff" },
      { opacity: 0, color: "rgb(14,26,19)" },
    ],
    from: { opacity: 0, color: "red" },
  });

  pingFx.play();

  return <animated.div style={styles}>{count}</animated.div>;
}

// TODO: Figure out better way to get Game model our for Game.code rather than passing down
export default function Init({ code }) {
  let [count, setCount] = useState(3);
  const [delay, setDelay] = useState(1000);
  const [updateRound] = useMutation(UPDATE_ROUND);

  // TODO: fix bug with interval where setCount is off from interval
  useInterval(() => {
    if (count === 1) {
      setDelay(null);

      // On complete mutation to update game status to request category
      updateRound({ variables: { code } });
    } else {
      setCount(count - 1);
    }
  }, delay);

  return (
    <div
      className="h-screen items-center justify-center flex flex-col"
      style={{ background: "#608267" }}
    >
      <div className="items-center justify-center w-full p-8 flex flex-col">
        <div className="bg-gray-400 items-center justify-center md:w-1/2 w-full shadow-md p-5 h-auto rounded-lg blur-3 bg-opacity-50 flex flex-col col-span-12">
          <h4 className="text-orange-300 text-2xl">Now Playing</h4>
          <h2 className="text-gray-300   text-5xl text-center mt-2 mb-20">
            {<Countdown count={count} />}
          </h2>
        </div>
      </div>
      {/* <button onClick={() => playAudio()}>Game Stuck?</button> */}
    </div>
  );
}
