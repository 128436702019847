import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  split,
  HttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";

// Generated Types
import generatedIntrospection from "./generated/possibleTypes.json";

// Pages
import Home from "pages/Home";
import Game from "pages/Host";
import Player from "pages/Player";
import JoinGame from "pages/JoinGame";
import Profile from "pages/Profile";

// https://www.apollographql.com/docs/react/api/link/apollo-link-context/
const authLink = setContext((_, { headers, options }) => {
  const token = localStorage.getItem("access_token");

  return {
    headers: {
      ...headers,
      authorization: token ? JSON.parse(token) : "",
    },
    options: {
      ...options,
      connectionParams: {
        authorization: token ? JSON.parse(token) : "",
      },
    },
  };
});
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPH_URI,
});
const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_GRAPH_WS,
  options: {
    reconnect: true,
    connectionParams: () => {
      const token = localStorage.getItem("access_token");

      return {
        authorization: token ? JSON.parse(token) : "",
      };
    },
  },
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const cache = new InMemoryCache({
  possibleTypes: generatedIntrospection,
});

const client = new ApolloClient({
  link: authLink.concat(link),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});

export default function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Route path="/" component={Home} exact />
        <Route path="/game/:code" component={Game} exact />
        <Route path="/join-game/:code?" component={JoinGame} />
        <Route path="/player/:player_id/game/:code" component={Player} exact />
        <Route path="/profile" component={Profile} exact />
      </Router>
    </ApolloProvider>
  );
}
