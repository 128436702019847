import React, { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_ROUND } from "pages/Host/queries";
import ReactAudioPlayer from "react-audio-player";

// import ProgressTimer from "../../../components/progressTimer";

export default function Question({ code, hostRound }) {
  const [updateRound] = useMutation(UPDATE_ROUND);

  const finishedAudio = useCallback(() => {
    updateRound({ variables: { code } });
  }, [updateRound, code]);

  return (
    <div>
      <h1>{hostRound.question.text.replace("<BLANK>", "______")}</h1>
      <p>Category: {hostRound.question.category}</p>
      <ReactAudioPlayer
        src={hostRound.question.audio}
        autoPlay
        onEnded={finishedAudio}
      />
    </div>
  );
}
