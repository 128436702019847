import React from "react";

export default function GameOver({ hostRound }) {
  return (
    <div>
      <h1>And the winner is...</h1>
      <ul>
        {[...hostRound.players]
          .sort((a, b) => b.score - a.score)
          .map((player, i) => (
            <li key={i}>
              {i === 0 && "👑"}
              {player.name} - {player.score}
            </li>
          ))}
      </ul>
    </div>
  );
}
