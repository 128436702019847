import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

// Hooks
import useLocalStorage from "../../../hooks/useLocalStorage";

const SIGNUP = gql`
  mutation SignUp($input: UserSignUpInput!) {
    SignUp(input: $input) {
      id
      token
    }
  }
`;

export default function Signup({ toggleShowLogin }) {
  const history = useHistory();
  const [, setToken] = useLocalStorage("access_token");
  const [SignUp] = useMutation(SIGNUP, {
    onCompleted: (data) => {
      const SignUp = data?.SignUp;
      if (SignUp?.token) {
        setToken(SignUp?.token);
        history.push(`/profile`);
      } else {
        alert("An Error Occured");
      }
    },
  });

  const [signUp, setSignUp] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
  });

  return (
    <div className="flex justify-center min-h-screen">
      <div className="container sm:mt-40 mt-24 my-auto max-w-md border-2 border-gray-200 p-3 bg-white">
        <div className="text-center my-6">
          <h1 className="text-3xl font-semibold text-gray-700">Bullshïttè</h1>
          <p className="text-gray-500">It's French For Fun!</p>
        </div>
        <div className="m-6">
          <form className="mb-4">
            <div className="mb-6">
              <label
                htmlFor="email"
                className="block mb-2 text-left text-sm text-gray-600 dark:text-gray-400"
              >
                Email Address
              </label>
              <input
                type="text"
                name="email"
                onChange={(event) =>
                  setSignUp({ ...signUp, email: event.target.value })
                }
                placeholder="Email"
                autoComplete="off"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 text-black dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="first_name"
                className="block mb-2 text-left text-sm text-gray-600 dark:text-gray-400"
              >
                First Name
              </label>
              <input
                type="text"
                name="first_name"
                onChange={(event) =>
                  setSignUp({ ...signUp, first_name: event.target.value })
                }
                placeholder="First Name"
                autoComplete="off"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 text-black dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="last_name"
                className="block mb-2 text-left text-sm text-gray-600 dark:text-gray-400"
              >
                Last Name
              </label>
              <input
                type="text"
                name="last_name"
                onChange={(event) =>
                  setSignUp({ ...signUp, last_name: event.target.value })
                }
                placeholder="Last Name"
                autoComplete="off"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 text-black dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="password"
                className="block mb-2 text-left text-sm text-gray-600 dark:text-gray-400"
              >
                Password
              </label>
              <input
                type="password"
                name="password"
                onChange={(event) =>
                  setSignUp({ ...signUp, password: event.target.value })
                }
                placeholder="Password"
                autoComplete="off"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 text-black dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
              />
            </div>
            <div className="mb-6">
              <button
                type="button"
                onClick={() => SignUp({ variables: { input: { ...signUp } } })}
                className="w-full px-3 py-4 text-white bg-indigo-500 rounded-md hover:bg-indigo-600 focus:outline-none duration-100 ease-in-out"
              >
                Sign up
              </button>
            </div>
            <p className="text-sm text-center text-gray-400">
              Already have an account yet?
              <span
                onClick={() => toggleShowLogin(true)}
                className="font-semibold text-indigo-500 focus:text-indigo-600 focus:outline-none focus:underline"
              >
                &nbsp;Login
              </span>
              .
            </p>
          </form>
          <div className="flex flex-row justify-center mb-8">
            <span className="absolute bg-white px-4 text-gray-500">
              or sign-in as guest
            </span>
            <div className="w-full bg-gray-200 mt-3 h-px"></div>
          </div>
          <div className="flex flex-row gap-2">
            <button
              onClick={() => {
                history.push(`/join-game`);
              }}
              className="bg-green-500 text-white w-full p-2 flex flex-row justify-center gap-2 items-center rounded-sm hover:bg-green-600 duration-100 ease-in-out"
            >
              Guest Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
