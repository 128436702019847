import React from "react";

export default function SelectCategory({ hostRound }) {
  return (
    <div>
      {hostRound.categories.map((category) => (
        <p key={category.id}>{category.name}</p>
      ))}
    </div>
  );
}
