import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";

import { UPDATE_ROUND } from "../queries";

export default function ShowScoring({ code, hostRound }) {
  const [updateRound] = useMutation(UPDATE_ROUND);

  useEffect(() => {
    const timer = setTimeout(() => {
      updateRound({ variables: { code } });
    }, 5000);
    return () => clearTimeout(timer);
  }, [code, updateRound]);

  return (
    <div>
      <h1>ShowPlayers</h1>
      <ul>
        {[...hostRound.players]
          .sort((a, b) => b.score - a.score)
          .map((player, i) => (
            <li key={i}>
              {player.name} - {player.score}
            </li>
          ))}
      </ul>
    </div>
  );
}
