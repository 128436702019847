import React from "react";

import "./style.css";

/**
 * You to ready up
 * Players to join and start
 * Players to answer
 * Round to finish (vote now)
 * Player to select category
 */
export default function PlayerWaiting() {
  return (
    <table>
      <tbody>
        <tr>
          <td>
            <span>
              <span></span>
              <span></span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
