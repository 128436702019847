import React from "react";

export default function PlayerListItem({ player, i }) {
  const readyTone = player.ready ? `tone-${i}` : "";

  return (
    <div className="flex justify-between items-center h-16 p-4 my-6  rounded-lg border border-gray-100 shadow-md">
      <div className="flex items-center">
        <div
          className={`h-12 w-12 ${readyTone}`}
          style={{
            fontSize: "2em",
            filter: `hue-rotate(30deg * ${i})`,
            display: "inline-block",
          }}
        >
          {player.ready ? <>💩</> : <>🚽</>}
        </div>
        <div className="ml-2">
          <div className="text-sm font-semibold text-gray-600" id={player.id}>
            {player.name}
          </div>
        </div>
      </div>
    </div>
  );
}
