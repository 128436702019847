import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";

const PLAYER_READY = gql`
  mutation SetPlayerReady($code: String!, $player_id: Int!) {
    SetPlayerReady(code: $code, player_id: $player_id)
  }
`;

// TODO: Should load current state of ready from server (related to below for player object)
export default function PlayerSelectReady({ code, currentPlayer }) {
  const [ready, setReady] = useState(currentPlayer.ready);
  const [playerReady] = useMutation(PLAYER_READY, {
    onCompleted: ({ SetPlayerReady }) => {
      setReady(SetPlayerReady);
    },
  });
  const readyCss = ready ? "green" : "red";

  // TODO: change player_id to name (might change when user auth implemented vs. guest)
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
      }}
    >
      <div className="container text-center" style={{ margin: "auto" }}>
        <h1 className="mb-4" style={{ color: ready ? "green" : "red" }}>
          {currentPlayer.name}
        </h1>
        <button
          className={`w-full px-3 py-4 text-white rounded-md hover:bg-${readyCss}-800 focus:outline-none duration-100 ease-in-out bg-${readyCss}-500`}
          onClick={() =>
            playerReady({
              variables: { code, player_id: parseInt(currentPlayer.id) },
            })
          }
        >
          {ready ? "Not Ready" : "Ready Up"}
        </button>
      </div>
    </div>
  );
}
