import { gql } from "@apollo/client";

/**
 * FRAGMENTS
 */
const PLAYER_FRAGMENT = gql`
  fragment PlayerDetails on Player {
    id
    name
    score
    ready
  }
`;

const GAME_FRAGMENT = gql`
  fragment GameDetails on Game {
    id
    code
    qr_code
    ready
  }
`;

const ROUND_FRAGMENT = gql`
  fragment RoundDetails on Round {
    ... on Lobby {
      players {
        ...PlayerDetails
      }
    }
    ... on RoundIntro {
      points
      number
    }
    ... on SelectCategory {
      categories {
        id
        name
      }
    }
    ... on Question {
      question {
        text
        category
        audio
      }
    }
    ... on ShowPlayers {
      players {
        ...PlayerDetails
      }
    }
    # subscribe to WaitingAnswers
    # send back players array and update as users submit
    # move to next round once timer ends or all users submit
    ... on WaitingAnswers {
      waiting {
        # cant use players becuase of weird issue with Lobby and ShowPlayers using the same
        id
        answered
      }
    }
    ... on WaitingSelectAnswers {
      text
      answers {
        id
        answer
      }
    }
    ... on ShowAnswers {
      answers {
        id
        answer
        players {
          id
          name
        }
      }
    }
    ... on ShowScoring {
      players {
        ...PlayerDetails
      }
    }
    ... on GameOver {
      players {
        ...PlayerDetails
      }
    }
  }
  ${PLAYER_FRAGMENT}
`;

/**
 * QUERIES
 */
export const GET_GAME = gql`
  query Game($code: String!) {
    Game(code: $code) {
      ...GameDetails
      hostRound {
        ...RoundDetails
      }
    }
  }
  ${GAME_FRAGMENT}
  ${ROUND_FRAGMENT}
`;

/**
 * MUTATIONS
 */
export const UPDATE_ROUND = gql`
  mutation UpdateRound($code: String!) {
    UpdateRound(code: $code)
  }
`;

export const ROUND_COUNTDOWN = gql`
  mutation RoundCountdown($code: String!, $seconds: Int!) {
    RoundCountdown(code: $code, seconds: $seconds)
  }
`;

export const GAME_CREATE = gql`
  mutation GameCreate {
    GameCreate {
      ...GameDetails
    }
  }
  ${GAME_FRAGMENT}
`;

/**
 * SUBSCRIPTIOINS
 */
export const HOST_ROUND_UPDATED = gql`
  subscription onHostRoundUpdated($code: String!) {
    HostRoundUpdated(code: $code) {
      ...RoundDetails
    }
  }
  ${ROUND_FRAGMENT}
`;

export const PLAYER_JOINED = gql`
  subscription onPlayerJoined($code: String!) {
    PlayerJoined(code: $code) {
      ...PlayerDetails
    }
  }
  ${PLAYER_FRAGMENT}
`;

export const PLAYER_READY = gql`
  subscription onPlayerReady($code: String!) {
    PlayerReady(code: $code) {
      ...PlayerDetails
    }
  }
  ${PLAYER_FRAGMENT}
`;

export const GAME_STARTED = gql`
  subscription onGameStarted($code: String!) {
    GameStarted(code: $code)
  }
`;

// export const GAME_STATUS = gql`
//   subscription onGameStatus($code: String!) {
//     GameStatus(code: $code)
//   }
// `;
